import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7548c4fa&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=7548c4fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7548c4fa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RPHTags: require('/usr/src/app/src/components/v2/atoms/RPHTags/index.vue').default,RPHTableProduct: require('/usr/src/app/src/components/v2/atoms/RPHTableProduct/index.vue').default,Heading: require('/usr/src/app/src/components/atoms/Heading.vue').default,ProductCounter: require('/usr/src/app/src/components/atoms/ProductCounter.vue').default,RPHButton: require('/usr/src/app/src/components/v2/atoms/RPHButton/index.vue').default})
